import React, { useContext, useRef } from 'react';
// import { Row, Col } from 'reactstrap';
import loadable from '@loadable/component';
import BarChartColumn from './BarChartColumn/BarChartColumn';
import './Display.css';
import { useCountUp } from 'react-countup';
import RoiContext from '../../../../../context/RoiContext';
import { Grid, Typography, makeStyles } from '@material-ui/core';

// const { useCountUp } = loadable(() => import('use-count-up'));

const useStyles = makeStyles((theme) => ({
  disclaimer: {
    padding: '2rem 0',
    [theme.breakpoints.down('md')]: {
      padding: '4rem 0',
    },
  },
}));

const Display = () => {
  const { calculatedValues, product } = useContext(RoiContext);
  const costRef = React.useRef(null);
  const timeRef = React.useRef(null);
  const workRef = React.useRef(null);

  const classes = useStyles();

  const {
    costCurrent,
    costSavings,
    priceAfterSavings,
    timeCurrent,
    timeAfterSavings,
    timeSaved,
    workCurrent,
    workSavings,
    workAfterSavings,
    timePeriod,
  } = calculatedValues;

  const { countUp: costSavingsCount, update: updateCostSavings } = useCountUp({
    start: 0,
    decimal: '.',
    separator: ',',
    decimals: 2,
    duration: 0.1,
    preserveValue: true,
    end: costSavings,
    redraw: true,
    // countUpRef: (test) => console.logtest()
  });

  const { countUp: timeSavedCount, update: updateTimeSaved } = useCountUp({
    start: 0,
    duration: 0.1,
    preserveValue: true,
    end: timeSaved,
  });

  const { countUp: workSavedCount, update: updateWorkSaved } = useCountUp({
    start: 0,
    duration: 0.1,
    preserveValue: true,
    end: workSavings,
  });

  updateCostSavings(costSavings);
  updateTimeSaved(timeSaved);
  updateWorkSaved(workSavings);

  return (
    <React.Fragment>
      <Grid
        container
        // className='cost-savings-header'
        justifyContent='center'
        style={{ margin: '4rem 0 2rem 0', textAlign: 'center' }}
      >
        <Typography variant='h2' color='primary'>
          Your Estimated Savings Per {timePeriod === 12 ? 'Month' : 'Year'}: ${costSavingsCount}
        </Typography>
      </Grid>
      <Grid container spacing={4}>
        <BarChartColumn
          unit='money'
          title='Cost'
          timePeriod={timePeriod}
          before={costCurrent}
          after={priceAfterSavings}
          product={product}
          // ref={costRef}
          copy={
            <Typography variant='body1'>
              Reduce your costs up to <b>${costSavingsCount}</b> equating to a <b>20% decrease</b> with PestPac RouteOp. Planning the most efficient routes will save you time, money, and
              fuel!
            </Typography>
          }
        />
        <BarChartColumn
          unit='time'
          title='Planning Time'
          timePeriod={timePeriod}
          before={timeCurrent}
          after={timeAfterSavings}
          product={product}
          // ref={timeRef}
          copy={
            <Typography variant='body1'>
              Automating route planning and scheduling with PestPac RouteOp will free up to <b>{timeSavedCount}</b> hours of your time, giving you <b>25% more time</b> to focus on what matters most – Growing your business!
            </Typography>
          }
        />
        <BarChartColumn
          unit='time'
          title='Working Time'
          timePeriod={timePeriod}
          before={workCurrent}
          after={workAfterSavings}
          product={product}
          // ref={workRef}
          copy={
            <Typography variant='body1'>
              Up to <b>{workSavedCount}</b> hours in reduced drive-time means lower fuel consumption and less wear and tear on your vehicles, not to mention <b>25% less hours</b> on the road.
            </Typography>
          }
        />
      </Grid>
      <Grid container justifyContent='center' className={classes.disclaimer}>
        <div className='disclaimer-text'>
          <Typography variant='body2'>
            <span>Notice:</span> The figures provided are estimates, generated using WorkWave's proprietary routing algorithm. Results may vary by
            company.
          </Typography>
        </div>
      </Grid>
    </React.Fragment>
  );
};

export default Display;
