import React from 'react';
// import PropTypes from 'prop-types';
import loadable from '@loadable/component';

import { Bar } from 'react-chartjs-2';
// import {Row, Col} from 'reactstrap';
import format from '../../../../utilities/format';
import './BarChartColumn.css';
import { Grid, Typography, Card, CardContent, makeStyles } from '@material-ui/core';

// const { Bar } = loadable.lib(() => import('react-chartjs-2'));

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.workwaveBlue,
    padding: '0px',
    borderRadius: '20px',
    minHeight: '300px',
    boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
    position: 'relative',
    // [theme.breakpoints.down('md')]: {
    // 	maxWidth: '500px',
    // },
    transition: 'transform 0.25s',
    [theme.breakpoints.down('md')]: {
      minHeight: '295px',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '125px',
    },
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 600,
    textAlign: 'left',
    color: '#183B56',
    marginLeft: '.5rem',
    lineHeight: 1.3,
    [theme.breakpoints.down('xs')]: {
      marginLeft: '1rem',
    },
  },
  pos: {
    // textAlign: 'center',
    lineHeight: '32px',
  },
  vl: {
    borderLeft: `6px solid ${theme.workwaveBlue}`,
    height: '50px',
    // position: 'absolute',
    // left: '50%',
  },
  result: {
    marginTop: '16px',
    minHeight: '110px',
    [theme.breakpoints.down('md')]: {
      minHeight: '295px',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '125px',
    },
  },
  resultWrapper: {
    minHeight: '110px',
    display: 'flex',
    flexDirection: 'column',
    padding: '6px 24px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      minHeight: '295px',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '125px',
    },
  },
}));

// ChartJS.register(
// 	CategoryScale,
// 	LinearScale,
// 	BarElement,
// 	Title,
// 	Tooltip,
// 	Legend
// );

const BarChartColumn = ({ before, after, copy, timePeriod, title, unit, product, ref }) => {
  const classes = useStyles();

  const barColor = {
    workwave: '#2F7FC1',
    routeManager: '#8CC82D',
    pestpac: '#1E488A',
    service: '#2F7FC1',
  };

  const theme = barColor[product];

  const defaultSettings = {
    labels: ['Current', product === 'routeManager' ? 'Route Manager' : 'Route Op'],
    backgroundColor: ['#055291', theme],
    hoverBackgroundColor: ['#055291', theme],
    categoryPercentage: 1.0,
    barPercentage: 0.9,
  };

  const dataSet = {
    labels: ['Current', product === 'routeManager' ? 'Route Manager' : 'Route Op'],
    datasets: [
      {
        ...defaultSettings,
        data: [before, after],
      },
    ],
  };

  const options = {
    animation: { duration: 2000 },
    scales: {
      display: false,
      xAxes: [
        {
          display: false,
        },
      ],
      yAxes: [
        {
          display: false,
          ticks: { beginAtZero: true },
        },
      ],
    },
    elements: { bar: { borderRadius: 15 } },
    legend: { display: false },
    tooltips: {
      titleFontFamily: "'Carnas-Light', sans-serif",
      titleFontSize: 16,
      bodyFontFamily: "'Carnas-Light', sans-serif",
      bodyFontSize: 14,
      // xPadding: 12,
      // yPadding: 12,
      callbacks: {
        label: function ({ yLabel }) {
          return format(yLabel, unit);
        },
      },
    },
    customToolTipWidth: 114,
  };

  return (
    <Grid item md={4} xs={12}>
      <Typography variant='h6' color='primary' style={{ textAlign: 'center' }}>
        {title} Per {timePeriod === 12 ? 'Month' : 'Year'}
      </Typography>
      <Bar data={dataSet} options={options} />
      <Grid container direction='column'>
        <Grid
          item
          style={{
            background: '#002D5C',
            color: 'white',
            borderRadius: '0px 0px 20px 20px',
            padding: '5px 0',
          }}
        >
          <Typography variant='h6' style={{ color: 'white', textAlign: 'center' }}>
            Current: {format(before, unit)}
          </Typography>
        </Grid>
        <Grid item container justifyContent='center'>
          <div className={classes.vl} />
        </Grid>

        <Card className={classes.root} elevation={0}>
          <Grid
            container
            alignItems='center'
            justifyContent='center'
            style={{
              top: 0,
              borderTop: `1px solid #002D5C`,
              background: '#002D5C',
              textAlign: 'center',
              height: '100%',
              padding: '7px 0',
            }}
          >
            <Typography variant='h6' style={{ color: 'white' }}>
              With RouteOp: {format(after, unit)}
            </Typography>
          </Grid>
          <CardContent className={classes.resultWrapper}>
            <Grid container item direction='row' justifyContent='flex-start' alignItems='flex-start' className={classes.result}>
              <Typography variant='body1' className={classes.pos}>
                {copy}
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default BarChartColumn;
