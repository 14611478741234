import roundedNum from './roundedNum';

export default (num, unit) => {
  if (unit === 'money') {
    // formats price
    return `$${roundedNum(num).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
  } else if (unit === 'time') {
    // adds commas to numbers greater than 999
    return `${roundedNum(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} hours`
  } else {
    return roundedNum(num);
  }
}